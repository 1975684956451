@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;400;700&display=swap');

$primary-color: #5263f4;
$theme-dark: #1d1e2c;
$black: #000000;

#auth-management {
    h1 {
        color: #000000;
    }
    h2 {
        color: #000000;
    }
    label {
        // color:#ffffff;
        color: #000000;
    }
    a {
        color: #36494a;
        &.dark-anchor {
            color: #8dada9;
        }
    }
    input[type='text'] {
        background-color: $theme-dark;
        border-color: $theme-dark;

        background-color: #ffffff;
        border-color: #ced4da;
        color: #000;
        &:focus {
            border-color: #577470;
        }
    }
    .mantine-PasswordInput-input {
        background-color: $theme-dark;
        border-color: $theme-dark;
        border: 0;
        background-color: #ffffff;
        border-color: #ced4da;
        color: #000;

        input[type='password'],
        input[type='text'] {
            background-color: $theme-dark;
            border-color: $theme-dark;
            border: 0;
            background-color: #ffffff;
            border-color: #ced4da;
            color: #000;
            &:focus {
                border-color: #577470;
            }
        }
    }

    .mantine-Checkbox-inner {
        input[type='checkbox'] {
            background-color: #ffffff;
            border-color: #ced4da;
        }
        svg {
            color: #000;
        }
    }

    button[type='submit'],
    button.submit {
        background-color: #577470;
        &:hover {
            background-color: #8dada9;
        }
        &[data-loading]::before {
            background-color: rgba(208, 255, 249, 0.58);
            // color:#577470;
        }
        &:disabled {
            svg {
                stroke: #000000;
            }
            span {
                color: #000000;
            }
        }
    }
    .mantine-PasswordInput-input {
        border-color: $theme-dark;

        border: 1px solid #ced4da;
        background-color: #000000;
        background-color: #ffffff;

        &:focus,
        &:focus-within {
            border-color: #577470 !important;
        }
    }
    .mantine-PasswordInput-rightSection {
        // background-color: $theme-dark;
        // button:hover{
        //   background-color: #000000;
        // }

        // background-color: #ffffff;
        // border: 1px solid #ced4da;
        color: #000;
        // border-top-right-radius: 4px;
        // border-bottom-right-radius: 4px;
        button {
            color: #000;
        }
        button:hover {
            background-color: #ffffff;
        }
    }
}

// app shell
.mantine-AppShell-root {
    &.nav-hover {
        #nav-wrapper {
            z-index: 2;
            max-width: 220px;
            .__mantine-ref-submenu {
                opacity: 1;
            }
        }

        #app-header .logoBg {
            min-width: 152px;
        }
        .app-header-menu-logo {
            margin-left: -16px !important;
        }
    }
}
.mantine-AppShell-body {
    height: calc(100vh - 60px);
    margin-top: 60px;
}
.mantine-AppShell-main {
    margin-left: 68px;
    transition: 0.2s all ease-in-out;
}
#app-header {
    position: fixed;

    .logoBg {
        height: 60px;
        max-width: 0;
        min-width: 0;
        left: 68px;
        // background:#000000;
        position: absolute;
        z-index: 0;
        transition: 0.1s all ease-in-out;
    }
    .app-header-menu-logo {
        transition: margin 0.2s ease-in-out;
        margin-left: 0px;
    }
}

.mantine-Modal-title {
    font-size: 18px;
    font-weight: 700;
}
#nav-wrapper {
    position: fixed;
    bottom: 0;
    top: 60px;
    max-width: 69px;
    overflow: hidden;
    transition: max-width 0.1s ease-in-out;
    z-index: 2;
    // button{
    //   background-color: transparent;
    // }

    .__mantine-ref-submenu {
        opacity: 0;
    }

    // &:hover{
    //   z-index: 2;

    //   .__mantine-ref-submenu{
    //     opacity: 1;
    //   }

    //   #app-header .logoBg {
    //     min-width: 152px;
    //   }
    // }
}

#sub-menu-wrapper {
    position: fixed;
    margin-top: 60px;
    bottom: 0;
    top: 0;
    border-left: 0;
    z-index: 1;
}
.main-container-header {
    z-index: 999;
    // position: sticky;
    top: 60px;
    min-height: 77px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-shrink:0;
    position:relative;
    top:0;
    
                    
    // background-color: #fff;
    // margin-left: -20px;
    // margin-right: -20px;
    // padding-left: 20px;
    // padding-right: 20px;
}
.main-container-content {
    &.show-data-grouped {
        thead {
            tr {
                th:first-of-type {
                    width: 30px;
                }
            }
        }
        tbody {
            tr {
                td:first-of-type {
                    width: 30px;
                }
            }
        }
    }
    &.show-data-grouped-hidden {
        thead {
            tr {
                th:first-of-type {
                    width: 0;
                    padding: 0;
                }
            }
        }

        tbody {
            tr {
                td:first-of-type {
                    width: 0px;
                    padding: 0;
                }
            }
        }
    }
}
.mantine-Tabs-tabLabel {
    line-height: 22px;
}
.tab-filter-search {
    min-width: 300px;
}
.pagination-footer {
    left: 85px;
    z-index: 1;
}

.nav-open {
    #nav-wrapper {
        max-width: 220px;

        .__mantine-ref-submenu {
            opacity: 1;
        }
    }
    .mantine-AppShell-main {
        margin-left: 68px;
        margin-left: 220px;
    }

    #app-header {
        .logoBg {
            min-width: 152px;
        }
    }

    .app-header-menu-logo {
        margin-left: -16px !important;
    }

    .pagination-footer {
        left: 240px;
    }
    // .displayFooter {
    //     left: 240px;
    // }
}

.right-detail-panel {
    position: sticky;
    top: 126px;
}

.button-clear-icon {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 3px;
}

.sample-pop {
    z-index: 9;
}

// MAP
.leaflet-control-layers {
    display: none;
}

/**** 
COPY CONTENT LINK
***/
.copy-content-link {
    position: relative;
    cursor: pointer;
    padding-right: 30px;
    background-color: transparent;
    .copy-content-link-btn {
        position: absolute;
        opacity: 0;
        right: 3px;
        transition: all 0.2s ease-in-out;
    }
    &:hover {
        .copy-content-link-btn {
            opacity: 1;
            right: 0px;
        }
    }
}

// #content-container{
//   padding-left: 200px;
// }

a {
    font-size: 14px;
    color: $primary-color;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.unhightable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**** 
TABLE 
***/
#mantine-rt-panel-styles {
    .mantine-ScrollArea-viewport > div {
        display: block !important;
    }
}
.h-scroll-table-container {
    // width: 100%;
    // overflow-x: auto;
    width: 100%;
    // overflow: auto;
    margin-right: 0px;
    margin-bottom: 50px;
    
    .h-scroll-table {
        border-collapse: separate;
        border-spacing: 0;
       

        td,
        th {
            // border: 1px solid black;
            padding: 0.5rem 1rem;
        }
        thead th {
            padding: 3px;
            position: sticky;
            top: 0;
            z-index: 2;
            // width: 25vw;
            // background: #131516;
            padding-left: 15px
        }
       
        tbody th {
            font-weight: 100;
            font-style: italic;
            text-align: left;
            position: relative;
            ;
        }
        thead th:nth-child(2) {
            position: sticky;
            left: 0;
            z-index: 3;
            // border-right: 1px solid black;
        }
        tbody tr.can-expand{
            td{
                vertical-align: middle !important;
            }
            td:nth-child(2){
                border-right:none
            }
        } 
        tbody td:nth-child(2) {
            position: sticky;
            left: 0;
            // background-color: #131516;
            z-index: 1;
            // border-right: 1px solid black;
            min-width: 100px;
            
        }
        caption {
            text-align: left;
            padding: 0.25rem;
            position: sticky;
            left: 0;
        }

    }
    .h-scroll-table-first-column {
        border-collapse: separate;
        border-spacing: 0;
        td,
        th {
            // border: 1px solid black;
            padding: 0.5rem 1rem;
        }
        thead th {
            padding: 3px;
            position: sticky;
            top: 0;
            z-index: 2;
            // width: 25vw;
            // background: #131516;
            padding-left: 15px
        }
       
        tbody th {
            font-weight: 100;
            font-style: italic;
            text-align: left;
            position: relative;
            ;
        }
        thead th:nth-child(1) {
            position: sticky;
            left: 0;
            z-index: 3;
            // border-right: 1px solid black;
        }
        tbody td:nth-child(1) {
            position: sticky;
            left: 0;
            // background-color: #131516;
            z-index: 1;
            // border-right: 1px solid black;
            min-width: 100px;
            
        }
        caption {
            text-align: left;
            padding: 0.25rem;
            position: sticky;
            left: 0;
        }

    }
}

/***************
// DROPDOWN SCROLL
/***************/
.mantine-MultiSelect-dropdown {
    div[data-orientation='horizontal'] {
        display: none;
    }
}

/***************
// RESIZABLE
****************/

.react-resizable {
    position: relative;
    // padding:0;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    z-index: 1;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: -8px;
    transform: rotate(45deg);
}
.box {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 25px -5px;
}

.progress-bar {
    .progress-inner {
        transition: all 1s ease-in-out;
    }
}

[data-rbd-droppable-id='board'] {
    display: flex;
    // background-color: rgb(76, 154, 255);
    // min-height: 100%;
    height: 100%;
    max-width: 100%;
    display: inline-flex;
}
.droppable-box {
    transition: all 0.2s ease-in;
}

// APPROVAL
.approvaltoolbar[data-layouttype='compare'] {
    &[data-selectedframe='1'] {
        .openseacontrols {
            opacity: 0.5;
            display: none;
        }
        .openseacontrols:last-of-type {
            opacity: 1;
            display: inline;
        }
    }
    &[data-selectedframe='0'] {
        .openseacontrols {
            opacity: 1;
        }
        .openseacontrols:last-of-type {
            opacity: 0.5;
            display: none;
        }
    }
}

.location-popup{
    .mapboxgl-popup-tip{
        display: none;
    }
}

.scan-image-holder{
    .openseacontrols > div{
        padding:0;
        gap:0;
    }
}

.scrollTableHeader{
    th{
        cursor: pointer;
        // &:hover{
        //     background-color: black;
        // }
    }
}

.spotlight-modal{
    backdrop-filter: blur(3px);
    .mantine-Modal-modal{
        padding:0;
        border-radius:12px;
        overflow: hidden;
    }
    .mantine-Modal-overlay{
        opacity: .25;
    }
    .mantine-Modal-inner{
        padding-top: 120px;
    }
}